<template>
    <crud-index-component v-bind="$data">
        <template slot="header">
            {{ $t('crud.pages._plural') }}
        </template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from '../crud/Index';
    import {config} from '../crud/mixin_config';

    export default {
        name: 'pages-index-component',
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('pages'),
                fields: [
                    {
                        title: this.$t('crud.pages.url'),
                        name: 'url',
                        sortField: 'url'
                    },
                    {
                        title: this.$t('crud.pages.title'),
                        name: 'title',
                        sortField: 'title'
                    },
                    {
                        title: this.$t('crud.status'),
                        name: 'status',
                        sortField: 'status',
                        formatter: (val) => {
                            switch(val) {
                                case 0:
                                    return '<span class="badge badge-pill badge-info text-white">Inactief</span>';
                                case 1:
                                    return '<span class="badge badge-pill badge-success text-white">Actief</span>';
                            }
                        }
                    },
                    {
                        title: this.$t('crud.updated_at'),
                        name: 'updated_at',
                        sortField: 'updated_at'
                    },
                    {
                        title: this.$t('crud.actions'),
                        name: 'slot-actions'
                    }
                ],
            };
        }
    }
</script>
